import HttpClient from 'server/helpers/HttpClient';
import { GetRepeatBusinessFeedbackParams } from 'server/routes/rest/mailchimp';
const httpClient = HttpClient.getInstance();
import { InvoiceFeedback, SurveySettingsType } from 'server/services/mailchimp/MessagingService';

export interface BaseParams {
  ssrtid: string;
  portal: string;
  realmId: string;
  acsToken: string;
  authToken: string;
}
export interface SubmitCRMSurveyParams extends BaseParams {
  data: {
    companyTxnId: string;
    companyEmail: string;
    payorNameId: string;
    customerDisplayName: string;
    locale: string;
    feedback: InvoiceFeedback;
  };
}

export interface GetRepeatBusinessFeedbackRequestParams extends BaseParams {
  data: GetRepeatBusinessFeedbackParams;
}

export interface GetSurveySettingsParams extends BaseParams {}
export interface GetSurveySettingsResponseData {
  qboRealmId: string;
  settingsKey: SurveySettingsType;
  settingsValue: string;
}

export const getSurveySettingsBL = async (args: GetSurveySettingsParams) => {
  const { ssrtid, portal, realmId, acsToken, authToken } = args;
  const restEndpoint = `/${portal}/rest/mailchimp/survey-settings`;

  const result = await httpClient.get(restEndpoint, {
    responseType: 'json',
    endpoint: restEndpoint,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'intuit-realmid': realmId,
      'ssr-session-id': ssrtid,
      'intuit-acstoken': acsToken,
    },
  });
  return result;
};

export const submitCRMSurveyBL = async (args: SubmitCRMSurveyParams) => {
  const { ssrtid, portal, realmId, acsToken, authToken } = args;
  const restEndpoint = `/${portal}/rest/mailchimp/submit-post-invoice-feedback`;

  const result = await httpClient.post(restEndpoint, args.data, {
    responseType: 'json',
    endpoint: restEndpoint,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'intuit-realmid': realmId,
      'ssr-session-id': ssrtid,
      'intuit-acstoken': acsToken,
    },
  });
  return result;
};

export const getRepeatBusinessFeedbackBL = async (args: GetRepeatBusinessFeedbackRequestParams) => {
  const { ssrtid, portal, realmId, acsToken, authToken } = args;
  const restEndpoint = `/${portal}/rest/mailchimp/repeat-business-feedback`;
  const result = await httpClient.get(restEndpoint, {
    responseType: 'json',
    endpoint: restEndpoint,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'intuit-realmid': realmId,
      'ssr-session-id': ssrtid,
      'intuit-acstoken': acsToken,
    },
    params: args.data,
  });
  return result;
};
