import { PayloadAction } from '@reduxjs/toolkit';

import { sliceFactory } from 'store/utils';
import { PostInvoiceSurvey, SurveyOpen } from 'types/PostInvoiceSurvey';

const initialState: PostInvoiceSurvey = {
  surveyOpen: SurveyOpen.None,
};

const { actions, reducer } = sliceFactory({
  name: 'postInvoiceSurvey',
  initialState,
  reducers: {
    setSurveyOpen: (state, action: PayloadAction<PostInvoiceSurvey>) => {
      const { surveyOpen } = action.payload;
      state.surveyOpen = surveyOpen;
    },
  },
});

export const postInvoiceSurveyReducer = reducer;
export const postInvoiceSurveyActions = actions;
