import HttpClient from 'server/helpers/HttpClient';
import SegmentIO from 'reporting/SegmentIO';
import { companyInfoSelectors } from 'store/companyInfo/selectors';
import { insightSelectors } from 'store/insight/selectors';

const { SplunkReporter } = require('reporting/splunk/SplunkReporter');
const splunkReporter = SplunkReporter.getInstance();
const logger = 'store/payment/payPal';
const httpClient = HttpClient.getInstance();

export const payPalCheckoutSetup = async (sale, insight, companyInfo, config, auth, payment) => {
  const companyLocale = companyInfoSelectors.localeSelector(companyInfo);
  let paypalMerchantId = companyInfoSelectors.paypalMerchantIdSelector(companyInfo);
  const isPayable = insightSelectors.isPayableSelector(insight);
  const { schema, ssrtid } = config;
  let environment = schema && schema.indexOf('prod') > -1 ? 'prod' : 'sandbox';
  try {
    if (isPayable) {
      const { payPalProcessor } = payment;
      if (payPalProcessor && payPalProcessor === 'APPCONNECT') {
        const paypalMerchantIdResult = await getAppConnectPayPalMerchantId(
          auth,
          config,
          insight,
          companyInfo
        );
        paypalMerchantId = paypalMerchantIdResult.success ? paypalMerchantIdResult.id : null;
        environment = paypalMerchantIdResult.prodEnv ? 'prod' : 'sandbox';
        let queryParams = window.location.search.slice(1);
        if (queryParams) {
          const params = queryParams.split('&');
          if (params.indexOf('sandbox=true') > -1) {
            environment = 'sandbox';
          }
        }
      }
    }
    if (paypalMerchantId) {
      window.paypalCheckoutReady = () => {
        window.paypal.checkout.setup(paypalMerchantId, {
          locale: companyLocale,
          // condition,
          environment,
          // button,
        });
      };
    }
  } catch (error) {
    splunkReporter.contextual({
      sessionId: ssrtid,
      logInfo: { logLevel: 'error', logger },
      event: 'pay',
      action: 'getMerchantId',
      activityInfo: { status: 'error' },
      error: {
        stack: error.stack,
        message: error.message,
      },
    });
  }
};

export const getAppConnectPayPalMerchantId = async (auth, config, insight) => {
  const { portal, ssrtid } = config;
  const token = insightSelectors.tokenSelector(insight);
  const domainId = insightSelectors.domainId(insight);

  const { entityId, realmId, ticket, isUserSignedIn, syncToken, authToken } = auth;
  const paypalEndPoint = `/${portal}/app/CommerceNetwork/view/rest/external-payment/merchantId`;
  /**
   * @type {import('axios').RawAxiosRequestHeaders}
   */
  const requestHeaders = {
    Accept: 'application/json, text/javascript, */*; q=0.01',
    'Intuit-DomainId': domainId,
    'Intuit-IntuitId': entityId,
    'Intuit-RealmId': realmId,
    'Intuit-ACSToken': token,
    sessionTicket: ticket,
    'user-signed-in': typeof isUserSignedIn === 'boolean' ? isUserSignedIn.toString() : 'false',
    syncToken: syncToken,
    Authorization: `Bearer ${authToken}`,
    'ssr-session-id': ssrtid,
  };

  const paypalMerchantIdResult = await httpClient({
    url: paypalEndPoint,
    method: 'GET',
    endpoint: paypalEndPoint,
    headers: requestHeaders,
    event: 'pay',
  });
  return paypalMerchantIdResult.data;
};

export const openPayPalSandBox = () => {
  window.paypal.checkout.reset();
  window.paypal.checkout.initXO();
};

export const getPayPalCheckOut = async (props) => {
  const {
    domainId,
    token,
    balanceAmount,
    paymentDueStatus,
    invoiceAmount,
    transactionType,
    currency,
    companyLocale,
    offerId,
    payment: { amount, payPalProcessor },
    auth: {
      ticket,
      realmId,
      isUserSignedIn,
      isEntityPromoted,
      entityId,
      syncToken,
      authToken,
      isSalesCheckoutInvoice,
      recipientEmail,
    },
    config: { ssrtid },
  } = props;
  const payorEmail = recipientEmail;
  let queryParams = window.location.search.slice(1);
  let params = [];
  if (queryParams) {
    params = queryParams
      .split('&')
      .filter((query) => query.indexOf('isPayPal') === -1 || query.indexOf('ssrtid') === -1);
  }
  params.push(['isPayPal=true']);
  params.push([`ssrtid=${ssrtid}`]);
  params.push([`payorEmail=${payorEmail}`]);
  if (transactionType) {
    params.push([`transactionType=${transactionType}`]);
  }

  if (!window.location.origin) {
    window.location.origin =
      `${window.location.protocol}//${window.location.hostname}` +
      (window.location.port ? ':' + window.location.port : '');
  }

  const returnURL = `${window.location.origin}${window.location.pathname}?${params.join('&')}`;
  const paymentBody = {
    amount,
    cancelURL: window.location.href,
    currencyCode: currency,
    guest: !isUserSignedIn,
    invoiceAmount,
    offerId: offerId || 'noOfferCode',
    paymentDueStatus: paymentDueStatus,
    paypalProcessor: payPalProcessor,
    prevBalance: balanceAmount,
    returnURL: returnURL,
  };

  const headers = {
    Accept: 'application/json, text/javascript, */*; q=0.01',
    'Content-Type': 'application/json',
    sessionticket: ticket,
    'intuit-authlevel': '5',
    'Intuit-RealmId': realmId,
    'Intuit-DomainId': domainId,
    'Intuit-IntuitId': entityId,
    'Intuit-ACSToken': token,
    'aws-wallet': true,
    token: token,
    isClientStateSignedIn: isUserSignedIn,
    isEntityPromoted: isEntityPromoted,
  };

  if (syncToken) {
    headers['syncToken'] = syncToken;
  }

  if (isSalesCheckoutInvoice) {
    headers['Authorization'] = `Bearer ${authToken}`;
  }
  const payPalCheckOut = await httpClient({
    url: '/icnportal-server/rest/payments/paypal/initPay',
    method: 'POST',
    headers,
    data: paymentBody,
    endpoint: '/icnportal-server/rest/payments/paypal/initPay',
    ssrtid,
    token,
    companyLocale,
  });
  return payPalCheckOut;
};

export const startPayPalFlow = (paypalToken) => {
  const paypalCheckoutUrl = `${window.paypal.checkout.urlPrefix}${paypalToken}&useraction=commit`;
  window.paypal.checkout.startFlow(paypalCheckoutUrl);
};

export const closePayPalFlow = () => {
  window.paypal.checkout.closeFlow();
};

export const createOrderOnPayPalCommerce = async (paymentsValue) => {
  const {
    auth: {
      entityId,
      realmId,
      ticket,
      isUserSignedIn,
      syncToken,
      authToken,
      isSalesCheckoutInvoice,
    },
    config: { portal, ssrtid },
    domainId,
    token,
    companyLocale,
    invoiceId,
    currency,
    isFullyPaid,
    isPartiallyPaid,
    openInvoices,
    balanceAmount,
    payment: { amount, paymentMethodType, ppCreateOrderKey },
    wallet: { enabledPaymentMethods },
  } = paymentsValue;
  try {
    const paypalEndPoint = `/${portal}/app/CommerceNetwork/view/rest/external-payment/createOrder`;
    /**
     * @type {import('axios').RawAxiosRequestHeaders}
     */
    const requestHeaders = {
      Accept: 'application/json, text/javascript, */*; q=0.01',
      'Intuit-DomainId': domainId,
      'Intuit-IntuitId': entityId,
      'Intuit-RealmId': realmId,
      'Intuit-ACSToken': token,
      sessionTicket: ticket,
      'user-signed-in': typeof isUserSignedIn === 'boolean' ? isUserSignedIn.toString() : 'false',
      syncToken: syncToken,
      'ssr-session-id': ssrtid,
      'x-forwarded-for': '127.0.0.1',
      invoiceId: invoiceId,
      'invoice-amount': amount,
      currencyCode: currency,
      'pp-create-order-key': ppCreateOrderKey,
    };
    if (isSalesCheckoutInvoice) {
      requestHeaders['Authorization'] = `Bearer ${authToken}`;
    }
    setTimeout(() => {
      SegmentIO.clickPayNow({
        paymentMethodType,
        enabledPaymentMethods,
        pymt_type_allowed: enabledPaymentMethods,
        payment_amount: amount,
        balanceAmount,
        currency: currency,
        isFullyPaid,
        isPartiallyPaid,
        openInvoices,
      });
    }, 0);
    const paypalOrderIdCapture = await httpClient({
      url: paypalEndPoint,
      method: 'GET',
      endpoint: paypalEndPoint,
      headers: requestHeaders,
    });
    if (paypalOrderIdCapture.data.merchantDetailsResp === '') {
      const error = new Error('No Order Id Returned from PayPal');
      error.invoiceInfo = {
        ssrtid,
        companyLocale,
        token,
        ppCreateOrderKey,
      };
      throw error;
    }
    return paypalOrderIdCapture.data.merchantDetailsResp;
  } catch (error) {
    error.invoiceInfo = {
      ssrtid,
      companyLocale,
      token,
      ppCreateOrderKey,
    };
    throw error;
  }
};
