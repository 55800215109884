import React from 'react';
import { FormattedMessage } from 'react-intl';

import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
const splunkReporter = SplunkReporter.getInstance();
const logger = 'pages/Feedback';
import { fontSize, colors, breakpoints } from 'styles/cp';

export const SUCCESS_SCREEN_LINK_URI = 'https://feedback.qbo.intuit.com/forums/591862';
export const MAIN_PAGE_LINK_URI = 'https://feedback.qbo.intuit.com/forums/920104';

export interface FeedbackLinkProps {
  sectionName?: 'success-screen' | 'main-section';
  transactionType?: 'INVOICE' | 'PAYMENT_REQUEST' | string;
}

export const FeedbackLink: React.FC<FeedbackLinkProps> = ({
  sectionName = 'main-section',
  transactionType = 'INVOICE',
}) => {
  let classNames = 'feedback-link',
    link = MAIN_PAGE_LINK_URI;

  if (sectionName === 'success-screen') {
    classNames += ' always-show';
    link = SUCCESS_SCREEN_LINK_URI;
  }

  if (transactionType === 'PAYMENT_REQUEST') {
    classNames += ' payment-request';
  }

  return (
    <React.Fragment>
      <a className={classNames} target="_blank" rel="noopener noreferrer" href={link}>
        <FormattedMessage
          id="INVOICE_FEEDBACK"
          defaultMessage="Tell us how we can make paying an invoice better?"
        />
      </a>

      <style jsx>{`
        .feedback-link {
          display: block;
          text-decoration: none;
          margin: 15px 0 15px;
          font-size: ${fontSize.xs};
          color: ${colors.blue};
          text-align: center;

          @media screen and (max-width: ${breakpoints.sm}) {
            font-size: ${fontSize.xxs};
          }

          &.always-show {
            @media screen and (max-width: ${breakpoints.md}) {
              margin: 15px 37px;
            }
          }

          &.payment-request {
            display: none;
          }

          a,
          a:visited,
          a:hover,
          a:active {
            text-decoration: none;
            color: inherit;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export interface FeedbackButtonProps {
  transactionType?: 'INVOICE' | 'PAYMENT_REQUEST';
}

export const FeedbackButton: React.FC<FeedbackButtonProps> = ({ transactionType = 'INVOICE' }) => {
  const onClick = () => {
    splunkReporter.contextual({
      logInfo: { logLevel: 'info', logger },
      event: 'feedbackLink',
      action: 'click',
      activityInfo: {
        activityObject: 'feedbackButton',
      },
    });
  };
  return (
    <React.Fragment>
      <div
        className={`${
          transactionType === 'PAYMENT_REQUEST' ? 'payment-request' : ''
        } feedback-w desktop`}
      >
        <a href={MAIN_PAGE_LINK_URI} rel="noopener noreferrer" target="_blank" onClick={onClick}>
          <span>Feedback</span>
        </a>
      </div>

      <style jsx>{`
        .payment-request {
          display: none;
        }

        .feedback-w.desktop {
          position: fixed;
          z-index: 999;
          transform-origin: bottom;
          transform: translate(50%, -100%) rotate(-90deg);
          right: 0px;
          bottom: 48px;
          height: 40px;
          line-height: 40px;

          a {
            background: ${colors.gray06};
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            border: solid 1px ${colors.gray06};
            text-decoration: none;
            color: ${colors.gray03};
            cursor: pointer;
            height: 40px;
            width: 125px;
            font-size: ${fontSize.sm};
            line-height: 40px;
            padding: 10px 25px;

            &:active {
              color: ${colors.gray03};
            }
          }

          @media screen and (max-width: ${breakpoints.md}) {
            display: none;
          }
        }

        .feedback-w.mobile {
          text-align: center;
          margin: 15px 0 10px;
          font-size: ${fontSize.xs};

          @media not screen and (max-width: ${breakpoints.md}) {
            display: none;
          }
        }
      `}</style>
    </React.Fragment>
  );
};
