import SegmentIO from 'reporting/SegmentIO';
import HttpClient from 'server/helpers/HttpClient';
const httpClient = HttpClient.getInstance();
import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
const splunkReporter = SplunkReporter.getInstance();
import { formatPaymentMethod } from 'shared/clientUtils';

export async function reportPageView({
  pageName,
  error,
  sale,
  insight,
  wallet,
  payment,
  auth,
  config,
  ixp,
}) {
  if (
    [
      'invoice',
      'paymentReq',
      'estimatePending',
      'estimateAccepted',
      'estimateDeclined',
      'depositPending',
      'depositAccepted',
      'depositDeclined',
      'subscription',
    ].indexOf(pageName) >= 0
  ) {
    await reportViewd({ sale, wallet, payment, ixp, insight, auth, config });
  } else if (pageName === 'PaymentSuccess') {
    reportPaymentSuccess({ payment, insight, sale, wallet, ixp });
  } else if (pageName === 'error') {
    invoiceErrorEvent({ error });
  }
}

async function reportViewd({ sale, wallet, payment, ixp, insight, auth, config }) {
  const { entityId, ticket, realmId, authToken, isSalesCheckoutInvoice } = auth;

  const { portal } = config;
  const segmentIOPayload = { ...sale, ...wallet, ...payment, ixp, ...insight };
  SegmentIO.invoiceViewedEvent(segmentIOPayload);
  SegmentIO.transactionViewed();
  const { appSourceOffering, domainId, token } = insight;
  try {
    const data = { status: 'VIEWED', appSourceOffering };
    let url = `/icnportal-server/rest/invoices/${domainId}`;
    let endpoint = '/icnportal-server/rest/invoices';
    const requestHeaders = {
      Accept: 'application/json, text/javascript, */*; q=0.01',
      'Intuit-DomainId': domainId,
      'Intuit-IntuitId': entityId,
      'Intuit-RealmId': realmId,
      'Intuit-ACSToken': token,
      sessionTicket: ticket,
    };
    if (isSalesCheckoutInvoice) {
      url = `/${portal}/rest/invoice/${token}/view`;
      endpoint = '/rest/invoice/:token';
      requestHeaders['Authorization'] = `Bearer ${authToken}`;
    }
    await httpClient({
      url,
      method: 'POST',
      data,
      headers: requestHeaders,
      endpoint,
    });
  } catch (error) {
    return;
  }
}

function reportPaymentSuccess({ payment, insight, sale, ixp }) {
  const {
    lastSuccessfulPaymentResponse,
    savePaymentMethodSuccessful,
    paymentMethodType,
    paymentStatus,
    paymentMethodSubType,
  } = payment;

  let amountPaid, trackingId;
  if (lastSuccessfulPaymentResponse) {
    amountPaid = lastSuccessfulPaymentResponse.amountPaid;
    trackingId = lastSuccessfulPaymentResponse.trackingId;
  }

  const {
    receivable: { dueDate: invoiceDueDate, balance: balanceAmount } = {},
    currencyInfo: { currency },
    amount: invoiceAmount,
    paymentDetailMessage: paymentDetailsMessage,
  } = sale;

  const { isFullyPaid, token } = insight;

  const { payPalProcessor = 'appconnect', isPayPalCommerceInvoice = false } = payment;

  const isPartiallyPaid = balanceAmount > 0 && balanceAmount < invoiceAmount;

  SegmentIO.paymentResult({
    status: paymentStatus === 'success',
    paymentMethod: null,
    paymentMethodType,
    payPalProcessor,
    amountPaid,
    savePaymentMethodSuccessful,
    trackingId,
    balanceAmount,
    paidInvoices: null,
    currency,
    isFullyPaid,
    isPartiallyPaid,
    invoiceDueDate,
    paymentDetailsMessage,
    ixp,
    token,
  });
  reportPaymentNewSyntax({
    amountPaid,
    currency,
    paymentMethod: 'PayPal',
    paymentStatus,
    trackingId,
    payPalProcessor,
    isPayPalCommerceInvoice,
    savePaymentMethodSuccessful,
    paymentMethodSubType,
  });
}

function invoiceErrorEvent({ error }) {
  error && SegmentIO.invoiceErrorEvent({ error });
}

export function reportInvoicePayInCP({
  sale = {},
  data,
  intuit_tid,
  selectedPaymentMethod,
  paymentType,
  gratuityValue = 0,
  isDateScheduled = false,
  isAutoPayOn = false,
  isNewWalletUI = false,
  inputAmount,
  paymentMethodSubType,
}) {
  const { currencyInfo: { currency } = {} } = sale;

  const {
    paymentMethod,
    amountPaid,
    trackingId,
    paymentStatus = '',
    savePaymentMethodSuccessful,
  } = data;
  reportPaymentNewSyntax({
    amountPaid,
    currency,
    gratuityValue,
    inputAmount,
    intuit_tid,
    isAutoPayOn,
    isDateScheduled,
    isNewWalletUI,
    paymentMethod,
    selectedPaymentMethod,
    paymentStatus,
    paymentType,
    trackingId,
    savePaymentMethodSuccessful,
    paymentMethodSubType,
  });
}
// the name of this method is temporary!
export const reportPaymentNewSyntax = ({
  amountPaid,
  currency,
  gratuityValue,
  intuit_tid,
  isAutoPayOn,
  isDateScheduled,
  isNewWalletUI,
  paymentMethod,
  selectedPaymentMethod,
  paymentStatus = '',
  paymentType,
  trackingId,
  error,
  payPalProcessor,
  isPayPalCommerceInvoice,
  inputAmount,
  savePaymentMethodSuccessful,
  paymentMethodSubType,
}) => {
  let formattedPaymentMethod = paymentMethod ?? selectedPaymentMethod;
  formattedPaymentMethod = formatPaymentMethod(formattedPaymentMethod);
  const successfullStatuses = ['SUCCESS', 'PENDING', 'CAPTURED', 'ORDER_CAPTURED', 'success'];
  const activityStatus = successfullStatuses.includes(paymentStatus)
    ? 'success'
    : paymentStatus.toLowerCase();
  // const status = typeof paymentStatus === 'string' && paymentStatus.toLowerCase();
  splunkReporter.contextual({
    logInfo: { logLevel: 'info', logger: 'businessLogic/Insight/Reporting' },
    event: 'pay',
    action: 'submit',
    activityInfo: {
      status: activityStatus,
      amount: amountPaid ?? inputAmount,
      currency: typeof currency === 'string' ? currency.toLowerCase() : currency,
      gratuityAmount: gratuityValue,
      intuitTid: intuit_tid,
      isAutoPayOn,
      isDateScheduled,
      isNewWalletUI,
      paymentMethod: formattedPaymentMethod,
      paymentType,
      trackingId,
      payPalProcessor,
      isPayPalCommerceInvoice,
      savePaymentMethodSuccessful,
      paymentMethodSubType,
    },
    error,
  });
};
