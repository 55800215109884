import { SaleLine } from 'types/Sale';
import { TXN_MAP } from 'types/constants';

interface saleAdapterProps {
  data: {
    invoiceAmount: number;
    customerName: string;
    currency: string;
    invoiceId: string;
    freightAmount: number;
    paymentDetailsMessage: string;
    invoiceNum: string;
    taxAmount: number;
    txnDate: string;
    transactionType: string;
    achPaymentEnabled: boolean;
    creditCardPaymentEnabled: boolean;
    paymentEnabled: boolean;
    partialPaymentEnabled: boolean;
    balanceAmount: number;
    dueDate: string;
    lineItems: Array<SaleLine>;
    recipientEmail: string;
    paymentEnabledForLocale: boolean;
    customerJobId: string;
    customerJobName: string;
    templateHeaderPrefs: {};
  };
}

const saleAdapter = ({ data }: saleAdapterProps) => {
  const {
    invoiceAmount,
    customerName,
    currency,
    invoiceId,
    freightAmount,
    paymentDetailsMessage,
    invoiceNum,
    taxAmount,
    txnDate,
    transactionType,
    achPaymentEnabled,
    creditCardPaymentEnabled,
    paymentEnabled,
    partialPaymentEnabled,
    balanceAmount,
    dueDate,
    lineItems,
    recipientEmail,
    paymentEnabledForLocale,
    templateHeaderPrefs,
  } = data;

  const contact = {
    displayName: customerName,
    toEmails: [recipientEmail],
  };

  const currencyInfo = {
    currency,
  };

  const entity = {
    globalId: {
      localId: invoiceId,
    },
  };

  const receivable = {
    allowOnlineACHPayment: achPaymentEnabled,
    allowOnlineCreditCardPayment: creditCardPaymentEnabled,
    allowPartialPayment: partialPaymentEnabled,
    allowOnlinePayment: paymentEnabled,
    balance: balanceAmount,
    dueDate, // TODO - Decide what is the format we are using
  };

  const tax = {
    totalTaxAmount: taxAmount,
  };

  const sale = {
    amount: invoiceAmount,
    contact,
    currencyInfo,
    description: null,
    discount: null,
    duty: null,
    entity,
    expirationDate: null,
    estimatedDelivery: null,
    freight: freightAmount,
    id: invoiceId,
    lines: lineItems,
    meta: null,
    paidAmount: null,
    paymentEnabledForLocale,
    paymentDetailMessage: paymentDetailsMessage,
    privateMemo: null,
    receivable,
    recurringInfo: null,
    referenceNumber: invoiceNum,
    requestedAmount: null, // only on SCS
    scheduleInfo: {
      autoPayEligible: false, // only on SCS
      schedulePayEligible: false, // only on SCS
      scheduleId: null,
      scheduleTemplateId: null,
      payor: null,
      scheduleType: null,
      scheduleDate: null,
      scheduleStatus: null,
    },
    acceptStatusInfo: null,
    isGpu: null,
    shipping: null, //not sure if can be calculated
    status: null,
    subType: null,
    subscriptionPaymentsSetting: null,
    tax,
    templateHeaderPrefs,
    txnDate,
    type: transactionType || TXN_MAP.TYPES.INVOICE,
  };
  return sale;
};

module.exports = saleAdapter;
export default saleAdapter;
