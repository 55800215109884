const HttpClient = require('server/helpers/HttpClient');
const httpClient = HttpClient.getInstance();
const { getWeakTicket } = require('shared/clientUtils');
const { companyInfoSelectors } = require('store/companyInfo/selectors');
const { SplunkReporter } = require('reporting/splunk/SplunkReporter');
const { isServer } = require('../../shared/utils');
const splunkReporter = SplunkReporter.getInstance();
const logger = 'server/routes/rest/bfs/index';

class WavefrontReporter {
  init({
    portal,
    domainId,
    entityId,
    realmId,
    token,
    ticket,
    isUserSignedIn,
    syncToken,
    authToken,
    ssrtid,
    pageName,
    language,
    region,
    offeringId,
    isSalesCheckoutInvoice,
  }) {
    this.baseEndpoint = `/${portal}/rest/reporting/prometheus`;
    this.headers = getWeakTicket({
      domainId,
      entityId,
      realmId,
      token,
      ticket,
      isUserSignedIn,
      syncToken,
      authToken,
      ssrtid,
    });
    this.ssrtid = ssrtid;
    this.token = token;
    this.baseTags = {
      flow: pageName,
      locale: companyInfoSelectors.localeSelector({ region, language }),
      offeringId,
      isSalesCheckoutInvoice,
    };

    if (!isServer() && window && window.location && window.location.href) {
      this.baseTags.fullPageUrl = window.location.href;
    }
  }

  static getInstance() {
    if (!WavefrontReporter._instance) {
      WavefrontReporter._instance = new WavefrontReporter();
    }
    return WavefrontReporter._instance;
  }

  registerPageLoadObserver() {
    const perfObserver = new PerformanceObserver((observedEntries) => {
      observedEntries.getEntries().forEach((entry) => {
        if (entry.duration === 0) {
          return;
        }
        this.trackPageLoad({ timeInMs: entry.domContentLoadedEventEnd });
        perfObserver.disconnect();
      });
    });

    perfObserver.observe({
      type: 'navigation',
      buffered: true,
    });
  }

  async trackView() {
    await this.track({ type: 'view' });
  }

  async trackPayment({ paymentMethod, status, subFlow, isPaymentUsingWallet }) {
    await this.track({
      type: 'payment',
      data: { paymentMethod, status, subFlow, isPaymentUsingWallet },
    });
  }

  async trackPageLoad({ timeInMs }) {
    await this.track({ type: 'pageLoad', data: { timeInMs } });
  }

  async track({ type, data = {} }) {
    try {
      if (!this.baseEndpoint) {
        // wavefront not initiated
        throw new Error('Tried to track with wavefront not initiated');
      }

      const url = `${this.baseEndpoint}/${type}`;
      await httpClient({
        url,
        method: 'POST',
        data: { ...this.baseTags, ...data },
        headers: this.headers,
        endpoint: url,
      });
    } catch (e) {
      splunkReporter.contextual({
        sessionId: this.ssrtid,
        logInfo: { logLevel: 'error', logger },
        event: 'report',
        action: 'WF',
        activityInfo: {
          type,
          data,
        },
        error: {
          message: e.message,
          stack: e.stack,
        },
      });
    }
  }
}

module.exports = WavefrontReporter;
