import HttpClient from 'server/helpers/HttpClient';
const httpClient = HttpClient.getInstance();

import { insightSelectors } from 'store/insight/selectors';
import { saleSelectors } from 'store/sale/selectors';

import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
const splunkReporter = SplunkReporter.getInstance();
const logger = 'store/payment/riskActions';
import SegmentIO from 'reporting/SegmentIO';

import { mark, wait } from 'shared/clientUtils';
import pWaitFor from 'p-wait-for';

export const reportPrePayment = async (paymentBody, state) => {
  try {
    let reportPrePaymentMark = mark('reportPrePayment');
    const { cardType, accountType, bankCode } = paymentBody;
    let domainId,
      token,
      balanceAmount = 0,
      invoiceAmount,
      currency = '',
      isFullyPaid = false,
      isPartiallyPaid = false,
      merchantId;

    /**
     * @type {string | null}
     */
    let riskProfileToken;

    const {
      auth: { regionIsoCode },
      payment: { paymentMethodType, amount },
      insight,
      sale,
      config: { ssrtid, bioCatchSessionId, biocatchExcludedStates },
      wallet: { userWallets },
      featureFlags = {},
      ixp,
    } = state;

    domainId = insightSelectors.domainId(insight);
    token = insightSelectors.tokenSelector(insight);
    balanceAmount = saleSelectors.balanceSelector(sale);
    invoiceAmount = saleSelectors.amountSelector(sale);
    currency = saleSelectors.currencySelector(sale);
    isFullyPaid = insightSelectors.isFullyPaidSelector(insight);
    isPartiallyPaid = insightSelectors.isPartiallyPaidSelector(insight);
    riskProfileToken = insightSelectors.riskProfileTokenSelector(insight);
    merchantId = insightSelectors.merchantIdSelector(insight);

    setTimeout(() => {
      SegmentIO.clickPayNow({
        paymentMethodType,
        payment_amount: amount,
        balanceAmount,
        currency,
        isFullyPaid,
        isPartiallyPaid,
        cardType,
        accountType,
        bankCode,
        userWallets,
        openInvoices: [],
        ixp,
      });
    }, 0);
    const notExcluded = biocatchExcludedStates && !biocatchExcludedStates.includes(regionIsoCode);

    if (notExcluded) {
      const {
        auth,
        config,
        insight: { offeringId },
      } = state;
      const {
        entityId,
        ticket,
        realmId,
        isUserSignedIn,
        syncToken,
        authToken,
        isSalesCheckoutInvoice,
      } = auth;

      await sendRiskPrepayment({
        ssrtid,
        token,
        config,
        entityId,
        realmId,
        ticket,
        isUserSignedIn,
        isSalesCheckoutInvoice,
        authToken,
        syncToken,
        merchantId,
        domainId,
        riskProfileToken,
        offeringId,
        customerSessionID: bioCatchSessionId,
        activityAmountTotal: invoiceAmount,
        activityAmount: amount,
        activityAmountBalance: balanceAmount,
        paymentInstrument: paymentMethodType,
        lockTimeout: featureFlags['on-payment-assessment-lock-timeout'],
      });
      reportPrePaymentMark.finish();
    } else {
      mark('did not sendRiskPrepayment');
      reportPrePaymentMark.finish();
      throw new Error(`excluded state, regionIsoCode: ${regionIsoCode}`);
    }
  } catch (e) {
    splunkReporter.contextual({
      logInfo: { logLevel: 'error', logger },
      event: 'risk',
      action: 'prePayment',
      activityInfo: {
        status: 'error',
      },
      error: {
        message: e.message,
        stack: e.stack,
      },
    });
    mark('error reportPrePayment');
  }
};

/**
 * @type {import('./riskActions').IsendRiskPrepayment}
 */
const sendRiskPrepayment = ({
  ssrtid,
  token,
  authToken,
  isSalesCheckoutInvoice,
  merchantId,
  config,
  domainId,
  entityId,
  realmId,
  ticket,
  isUserSignedIn,
  syncToken,
  customerSessionID,
  activityAmountTotal,
  activityAmount,
  activityAmountBalance,
  paymentInstrument,
  riskProfileToken,
  offeringId,
  lockTimeout = 0,
}) => {
  try {
    // call Risk prepayment
    let sendRiskPrepaymentMark = mark('sendRiskPrepayment');
    const { portal } = config;
    const riskEndPoint = `/${portal}/rest/risk/prepayment`;

    /**
     * @type {import('axios').RawAxiosRequestHeaders}
     */
    const requestHeaders = {
      Accept: 'application/json, text/javascript, */*; q=0.01',
      'Intuit-DomainId': domainId,
      'Intuit-IntuitId': entityId,
      'Intuit-RealmId': realmId,
      'Intuit-ACSToken': token,
      sessionTicket: ticket,
      'user-signed-in': typeof isUserSignedIn === 'boolean' ? isUserSignedIn.toString() : 'false',
      syncToken: syncToken,
      'ssr-session-id': ssrtid,
    };

    if (isSalesCheckoutInvoice) {
      requestHeaders['Authorization'] = `Bearer ${authToken}`;
    }

    const requestData = {
      merchantId,
      customerSessionID,
      activityAmountTotal,
      activityAmount,
      activityAmountBalance,
      paymentInstrument,
      token,
      riskProfileToken,
      offeringId,
    };
    return httpClient
      .post(riskEndPoint, requestData, {
        endpoint: riskEndPoint,
        headers: requestHeaders,
      })
      .then(() => {
        let shouldWait = typeof lockTimeout === 'number' && lockTimeout > 0;
        if (shouldWait) {
          let waitForBiocatch = mark('lockForBFSPrePaymentToGetBiocatch');
          return wait(lockTimeout).then(() => {
            waitForBiocatch.finish();
          });
        } else {
          return Promise.resolve();
        }
      })
      .then(() => sendRiskPrepaymentMark.finish());
  } catch (e) {
    mark('error sendRiskPrepayment');
    return Promise.reject(e instanceof Error && e.message);
  }
};

/**
 * A riskProfileToken must:
 * Be 69 characters total, with one ':' in the middle.
 * Have 32 characters before the ':' and 36 after the ':'
 * Made to be a bit more flexible just in case the length ever changes
 * @param {string | null} riskProfileToken
 * @returns boolean
 */
export const isValidriskProfileToken = (riskProfileToken) =>
  typeof riskProfileToken === 'string' &&
  /^[0-9A-Za-z]{20,40}:[0-9a-z-A-Z]{20,40}/.test(riskProfileToken);

/**
 * locks until either riskProfileToken is valid, or till the lock timeout has passed
 * @type {import('./riskActions').lockForriskProfileToken}
 */
export async function lockForriskProfileToken(getState) {
  let {
    featureFlags: { 'risk-profile-token-lock-timeout': lockTimeout } = {
      'risk-profile-token-lock-timeout': 0,
    },
  } = getState();

  if (typeof lockTimeout === 'number' && lockTimeout > 0) {
    let markLock = mark('lockForriskProfileToken');
    await pWaitFor(
      () => {
        let {
          insight: { riskProfileToken },
        } = getState();
        return isValidriskProfileToken(riskProfileToken);
      },
      {
        timeout: lockTimeout, // will reject if condition doesn't return true till this time in ms
        interval: 50, // how often to check condition in milliseconds
      }
    ).catch(() => {
      markLock.finish();
      throw new Error('never received riskProfileToken');
    });
    markLock.finish();
  }
}
